<template>
  <div style="height:100vh;">
    <div class="header">
      <div class="header-left"></div>
      <div class="header-title">
        运天下物流大数据中心
      </div>
      <div class="header-right">{{ date }}</div>
    </div>
    <div class="page">
      <router-view v-if="flag" :selectRangeDate="selectRangeDate"></router-view>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      activeName: "month", // 默认显示近一月
      modal: false,
      flag: false,
      selectRangeDate: [],
      startTime: "",
      endTime: "",
      optionStart: {
        disabledDate(date) {
          // 禁止选择今天之后的日期
          return date && date.valueOf() > Date.now() - 86400000;
        },
      },
      optionEnd: {},
      resizeFn: null,
      date: moment(new Date()).format("yyyy-MM-DD  HH:mm:ss"),
      timer: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeFn);
    this.handleSelect(this.activeName); // 默认显示近一个月
    this.setDate()
  },
  methods: {
    setDate() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.date = moment(new Date()).format("yyyy-MM-DD  HH:mm:ss");
      }, 1000);
    },

    pickStartDate(date) {
      // 选择开始时间的回调
      this.startTime = date;
      this.optionEnd = {
        disabledDate(d) {
          // 禁止选择开始时间之前的日期
          return d && d.valueOf() < new Date(date).valueOf() - 86400000;
        },
      };
    },
    pickEndDate(date) {
      // 选择结束时间的回调
      this.endTime = date;
    },
    getMonthBetween(start, end) {
      // 获取开始时间和结束时间之内的所有月份
      this.selectRangeDate = [];
      let s = start.split("-"); // 字符串装换数组
      let e = end.split("-");
      let date = new Date();
      let min = date.setFullYear(s[0], s[1] - 1); // 设置最小时间
      let max = date.setFullYear(e[0], e[1] - 1); // 设置最大时间
      let curr = min;
      while (curr <= max) {
        // 循环添加月份
        var month = curr.getMonth();
        var arr = [curr.getFullYear(), month + 1];
        this.selectRangeDate.push(arr);
        curr.setMonth(month + 1);
      }
    },
    getDays(day) {
      // 获取天数
      let arr = [];
      for (let i = -day; i < 0; i++) {
        // 循环添加天数
        let today = new Date(); // 获取今天
        let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * i;
        today.setTime(targetday_milliseconds); //设置i天前的时间
        let tYear = today.getFullYear();
        let tMonth = today.getMonth();
        let tDate = today.getDate();
        let date = [tYear, tMonth + 1, tDate];
        arr.push(date);
      }
      return arr;
    },
    handleSelect(name) {
      switch (name) {
        case "day":
          break;
        case "week":
          this.selectRangeDate = this.getDays(7); // 获取近一周的天数
          this.flag = true;

          break;
        case "month":
          this.selectRangeDate = this.getDays(30); // 获取近一个月的天数
          this.flag = true;
          break;
        case "filter":
          this.modal = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: DOUYU;
  src: url("../assets/fonts/douyu.ttf");
}


.header {
  height: 60px;
  background: #061e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/header.png");
  background-size: 1440px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  position: relative;

  &-right {
    position: absolute;
    right: 50px;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    bottom: 16px;
  }

  &-title {
    // color: #75deef;
    // font-size: 30px;
    // letter-spacing: 10px;
    // position: absolute;
    // left: 533px;
    // top: 2px;
    // width: 388px;
    // height: 43.61px;
    // opacity: 1;

    font-family: DOUYU;
    font-size: 28px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 9px;
    color: #ffffff;
  }
}

.page {
  height: calc(~"100% - 60px");
}
</style>
